import { useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import fileDownload from 'js-file-download';
import lightFormat from 'date-fns/lightFormat';
import { useToasts } from 'react-toast-notifications';

import { DatePicker } from 'components/core/DatePicker/DatePicker';
import { API_ENDPOINTS } from 'constants/API-ENDPOINTS';

const ReservationsPerDay = () => {
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const downloadReservationsPerDay = async () => {
    const fileName = `${lightFormat(date, 'yyyy-MM-dd')}.csv`;

    setLoading(true);

    try {
      const response = await axios.post(
        API_ENDPOINTS.DOWNLOAD_RESERVATIONS_PER_DAY,
        {
          timestamp: date.getTime()
        }
      );

      fileDownload(response.data, fileName);
    } catch (e) {
      addToast(e.response.data.messages[0], { appearance: 'error' });
    }

    setLoading(false);
  };

  return (
    <div className="card">
      <div className="card-header">Reservations per day</div>
      <div className="card-body">
        <Form.Group>
          <DatePicker selectedDate={date} onChange={date => setDate(date)} />
        </Form.Group>
        <Button onClick={() => downloadReservationsPerDay()} disabled={loading}>
          Download
        </Button>
        <Form.Text className="text-muted mt-3">
          The document will be downloaded in <strong>CSV</strong> format
        </Form.Text>
      </div>
    </div>
  );
};

export { ReservationsPerDay };
