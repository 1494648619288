import { useState } from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import styles from './CheckIn.module.css';

import { Title } from 'components/Title/Title';
import { routes } from 'routes';
import { Team } from 'components/Team/Team';

function CheckInStart({ teams }) {
  const [selectedTeam, setSelectedTeam] = useState('');
  return (
    <div>
      <Title>Select your team:</Title>
      <form className="group-button-holder">
        {teams
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((team, index) => (
            <Team
              key={index}
              team={team}
              onTeamSelect={id => {
                setSelectedTeam(id);
              }}
            />
          ))}
      </form>
      {selectedTeam && (
        <div className="d-flex flex-column align-items-center">
          <Link
            to={{
              pathname: routes.checkInReview,
              state: { selectedTeam: selectedTeam }
            }}
          >
            <button type="button mx-2" className="check-in-button">
              Start
            </button>
          </Link>
          <div className="make-sure-title mt-2 p-2">
            Make sure the entire team has submitted their answers before
            starting.
          </div>
        </div>
      )}
    </div>
  );
}

export { CheckInStart };
