import app from 'firebase/app';

import 'firebase/auth';
import { firebaseConfig } from 'config/firebase';

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.googleProvider.setCustomParameters({
      hd: firebaseConfig.hostedDomain
    });
  }

  signIn = async () => {
    try {
      const user = await this.auth.signInWithPopup(this.googleProvider);
      // TODO: This is a workaround. We need to find a better solution for retrieving the google idToken
      // For some reason, when you successfully login with Google, you will get the idToken from google in the response
      // But if you refresh the page, Firebase is giving you only the Firebase idToken
      localStorage.setItem('google.idToken', user.credential.idToken);
    } catch (e) {
      console.error(e);
    }
  };

  signOut = async () => {
    try {
      await this.auth.signOut();
      localStorage.removeItem('google.idToken');
    } catch (e) {
      console.log(e);
    }
  };
}

export { Firebase };
