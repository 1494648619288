import { useEffect } from 'react';

import { GoBackLink } from 'components/core/GoBackLink/GoBackLink';

function Confetti({ ctx, height, width }) {
  //construct confetti
  const colours = ['#fde132', '#009bde', '#ff6b00'];

  this.ctx = ctx;
  this.height = height;
  this.width = width;
  this.x = Math.round(Math.random() * this.width);
  this.y = Math.round(Math.random() * this.height) - this.height / 2;
  this.rotation = Math.random() * 360;

  const size = Math.random() * (this.width / 60);
  this.size = size < 15 ? 15 : size;

  this.color = colours[Math.floor(colours.length * Math.random())];

  this.speed = this.size / 7;

  this.opacity = Math.random();

  this.shiftDirection = Math.random() > 0.5 ? 1 : -1;
}

Confetti.prototype.border = function () {
  if (this.y >= this.height) {
    this.y = this.height;
  }
};

Confetti.prototype.update = function () {
  this.y += this.speed;

  if (this.y <= this.height) {
    this.x += this.shiftDirection / 5;
    this.rotation += (this.shiftDirection * this.speed) / 100;
  }

  this.border();
};

Confetti.prototype.draw = function () {
  this.ctx.beginPath();
  this.ctx.arc(
    this.x,
    this.y,
    this.size,
    this.rotation,
    this.rotation + Math.PI / 2
  );
  this.ctx.lineTo(this.x, this.y);
  this.ctx.closePath();
  this.ctx.globalAlpha = this.opacity;
  this.ctx.fillStyle = this.color;
  this.ctx.fill();
};

function CheckInFinished() {
  useEffect(() => {
    /** @type {HTMLCanvasElement} */
    const canvasEl = document.querySelector('#canvas');

    const height = (canvasEl.height = window.innerHeight * 2);
    const width = (canvasEl.width = window.innerWidth);

    const ctx = canvasEl.getContext('2d');
    const confNum = Math.floor(width / 3);
    const confetties = new Array(confNum)
      .fill()
      // eslint-disable-next-line no-unused-vars
      .map(_ => new Confetti({ ctx, height, width }));
    loop();

    function loop() {
      requestAnimationFrame(loop);
      ctx.clearRect(0, 0, width, height);

      confetties.forEach(conf => {
        conf.update();
        conf.draw();
      });
    }
  });

  return (
    <div>
      <GoBackLink />
      <div className="submissions">Session Finished!</div>
      <canvas id="canvas"></canvas>
    </div>
  );
}

export { CheckInFinished };
