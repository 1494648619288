import { Title } from 'components/Title/Title';
import { ReservationsPerDay } from 'components/ReservationsPerDay';
import { ReservationSettings } from 'components/ReservationSettings';
import { ReservationsDirectContact } from 'components/ReservationsDirectContact';

const Reservations = () => {
  return (
    <div>
      <Title>Reservations</Title>
      <div className="row mt-5">
        <div className="col">
          <ReservationsPerDay />
        </div>
        <div className="col">
          <ReservationsDirectContact />
        </div>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col">
          <div className="card">
            <div className="card-header">Settings</div>
            <div className="card-body">
              <ReservationSettings />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Reservations };
