import { useEffect, useState } from 'react';
import axios from 'axios';
import subMonths from 'date-fns/subMonths';

import styles from './MoodOverTime.module.css';

import { DatePicker } from 'components/core/DatePicker/DatePicker';
import { API_ENDPOINTS } from 'constants/API-ENDPOINTS';
import { MoodChart } from 'components/moodOverTime/MoodChart/MoodChart';
import { TEAMS } from 'constants/TEAMS';
import { Team } from 'components/Team/Team';
import { Title } from 'components/Title/Title';

const MoodOverTime = () => {
  const [startDate, setStartDate] = useState(subMonths(new Date(), 6));
  const [endDate, setEndDate] = useState(new Date());
  const [competence, setCompetence] = useState('Mobile');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [moodSummary, setMoodSummary] = useState([]);

  useEffect(() => {
    fetchMoodOverTime();
  }, [startDate, endDate, competence]);

  const fetchMoodOverTime = async () => {
    setLoading(true);
    try {
      let response = await axios.get(API_ENDPOINTS.MOOD_OVER_TIME_SUMMARY, {
        params: { team: competence, dateFrom: startDate, dateTo: endDate }
      });
      let data = response.data;

      setMoodSummary(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <main className={styles.root}>
      <Title>Mood over time summary</Title>
      <section className={styles.calendar}>
        <DatePicker
          selectedDate={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
        <DatePicker
          selectedDate={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </section>
      <section className={styles.teams}>
        {TEAMS.map(team => (
          <Team
            key={team.id}
            team={team}
            onTeamSelect={id => setCompetence(id)}
            className="team"
          />
        ))}
      </section>
      <hr />
      <section className={styles.container}>
        {moodSummary.length ? (
          <MoodChart data={moodSummary} />
        ) : (
          <p className={styles.empty}>No data</p>
        )}
      </section>
    </main>
  );
};

export { MoodOverTime };
