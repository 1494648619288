import axios from 'axios';
import { useState, useEffect } from 'react';

import { API_ENDPOINTS } from './constants/API-ENDPOINTS';

function AuthInterceptor({ idToken }) {
  const [authInterceptor, setAuthInterceptor] = useState(null);

  const addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(
      config => {
        if (
          config.url.startsWith(API_ENDPOINTS.BASE) &&
          !config.headers.Authorization
        ) {
          const token = idToken;
          if (token) {
            config.headers.Authorization = `${token}`;
          }
        }
        return config;
      },
      error => Promise.reject(error)
    );
    setAuthInterceptor(authInterceptor);
  };

  const removeAuthInterceptor = () => {
    axios.interceptors.request.eject(authInterceptor);
    setAuthInterceptor(null);
  };

  useEffect(() => {
    addAuthInterceptor();
    return () => {
      removeAuthInterceptor();
    };
  }, [idToken]);

  return null;
}

export { AuthInterceptor };
