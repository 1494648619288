import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { NO_CONTENT } from 'http-status-codes';
import { ClipLoader } from 'react-spinners';

import { routes } from 'routes';
import { TeamMemberMood } from 'components/Team-member-mood/TeamMemberMood';
import { API_ENDPOINTS } from 'constants/API-ENDPOINTS';
import { GoBackLink } from 'components/core/GoBackLink/GoBackLink';
import { NoSubmissions } from 'components/No-submissions/NoSubmissions';

const CheckInReview = props => {
  const { location } = props;
  const [mobster, setMobster] = useState(null);
  const [submissionsLeft, setSubmissionsLeft] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [responseError, setError] = useState(null);
  const [isLoadingMobster, setIsLoadingMobster] = useState(false);

  const fetchMobster = useCallback(async () => {
    const { state } = location;
    const team = state.selectedTeam;
    setSelectedTeam(team);
    if (!selectedTeam && !submissionsLeft) {
      return;
    }

    try {
      setIsLoadingMobster(true);
      const res = await axios.get(API_ENDPOINTS.FETCH_CHECKIN, {
        params: { team: selectedTeam }
      });

      if (res.status === NO_CONTENT || !res.data) {
        setSubmissionsLeft(false);
      } else {
        setMobster(res.data);
        setSubmissionsLeft(Object.values(res.data).some(x => x));
      }
      setIsLoadingMobster(false);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  }, [selectedTeam]);

  useEffect(
    function loadMobster() {
      fetchMobster();
    },
    [selectedTeam]
  );

  let content;
  if (responseError) {
    content = (
      <div>
        <p className="text-center">Ooops... Something went wrong !</p>
      </div>
    );
  } else if (isLoadingMobster) {
    content = (
      <div className="loader">
        <ClipLoader size={150} color={'#29ace3'} />;
      </div>
    );
  } else if (!submissionsLeft) {
    if (mobster) {
      window.location.href = routes.checkinFinished;
    } else {
      content = <NoSubmissions />;
    }
  } else if (submissionsLeft) {
    content = (
      <TeamMemberMood mobster={mobster} fetchMobster={() => fetchMobster()} />
    );
  }

  return (
    <>
      <GoBackLink />
      {content}
    </>
  );
};

export { CheckInReview };
