import { useEffect, useState } from 'react';

function useGAPI() {
  const [gapi, setGAPI] = useState(null);

  useEffect(() => {
    if (window.gapi) {
      setGAPI(window.gapi);
    } else {
      const script = document.querySelector(
        'script[src="https://apis.google.com/js/platform.js"]'
      );

      const onLoad = () => setGAPI(window.gapi);

      script.addEventListener('load', onLoad);

      () => script.removeEventListener('load', onLoad);
    }
  }, []);

  return gapi;
}

export { useGAPI };
