import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import cx from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './DatePicker.module.css';

const propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date)
};

const DatePicker = ({
  className,
  selectedDate,
  startDate,
  endDate,
  onChange,
  selectsStart,
  minDate,
  maxDate
}) => (
  <ReactDatePicker
    className={cx(styles.root, className)}
    selected={selectedDate}
    startDate={startDate}
    endDate={endDate}
    selectsStart={selectsStart}
    onChange={onChange}
    minDate={minDate}
    maxDate={maxDate}
    calendarClassName="datepicker"
    dateFormat="MMMM d, yyyy"
  />
);

DatePicker.propTypes = propTypes;

export { DatePicker };
