import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

import { Modal } from 'components/core/Modal/Modal';
const ReservationEventModal = ({ show, onHide, event, onSubmit }) => {
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState(null);
  const [numberOfSeats, setNumberOfSeats] = useState(null);

  useEffect(() => {
    setDescription(event.description || '');
    setNumberOfSeats(event.numberOfSeats || '');
  }, [event]);

  const clearForm = () => {
    setValidated(false);
    setDescription('');
    setNumberOfSeats('');
  };

  const handleClose = () => {
    clearForm();
    onHide();
  };

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.currentTarget.checkValidity()) {
      event.description = description;
      event.numberOfSeats = numberOfSeats;
      onSubmit(event);
    }

    setValidated(true);
  };

  return (
    <Modal
      open={show}
      handleClose={handleClose}
      title="Settings for"
      handleAction={handleSubmit}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            The description is a required field
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="numberOfSeats">
          <Form.Label>Number of seats:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter number of seats"
            value={numberOfSeats}
            onChange={e => setNumberOfSeats(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Invalid number of seats
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </Modal>
  );
};

export { ReservationEventModal };
