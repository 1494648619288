import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import Col from 'react-bootstrap/Col';
import lightFormat from 'date-fns/lightFormat';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useToasts } from 'react-toast-notifications';

import { DatePicker } from 'components/core/DatePicker/DatePicker';
import { API_ENDPOINTS } from 'constants/API-ENDPOINTS';

const ReservationsDirectContact = () => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));
  const [email, setEmail] = useState('');
  const { addToast } = useToasts();

  const downloadBookings = async () => {
    const fileName = `${email}_${lightFormat(
      startDate,
      'yyyy-MM-dd'
    )}_${lightFormat(endDate, 'yyyy-MM-dd')}.csv`;

    setLoading(true);

    try {
      const response = await axios.post(API_ENDPOINTS.DOWNLOAD_DIRECT_CONTACT, {
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
        email
      });
      fileDownload(response.data, fileName);
    } catch (e) {
      addToast(e.response.data.messages[0], { appearance: 'error' });
    }

    setLoading(false);
  };

  return (
    <div className="card">
      <div className="card-header">People in contact with</div>
      <div className="card-body">
        <Form.Group>
          <Form.Row>
            <Form.Label column lg={2}>
              From:
            </Form.Label>
            <Col>
              <DatePicker
                selectedDate={startDate}
                onChange={date => setStartDate(date)}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Row>
            <Form.Label column lg={2}>
              To:
            </Form.Label>
            <Col>
              <DatePicker
                selectedDate={endDate}
                onChange={date => setEndDate(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row>
            <Form.Label column lg={2}>
              Email:
            </Form.Label>
            <Col>
              <Form.Control
                type="text"
                size="sm"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Col>
          </Form.Row>
        </Form.Group>

        <Button onClick={() => downloadBookings()} disabled={loading}>
          Download
        </Button>
        <Form.Text className="text-muted mt-3">
          The document will be downloaded in <strong>CSV</strong> format
        </Form.Text>
      </div>
    </div>
  );
};

export { ReservationsDirectContact };
