import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import 'styles/normalize.scss';
import 'styles/base.scss';

import './App.scss';
import { SignIn } from './components/SignIn/SignIn';
import { AuthInterceptor } from './interceptors';
import { Home } from './pages/home';
import { Header } from './components/core/Header/Header';
import { MoodOverTime } from './pages/MoodOverTime/MoodOverTime';
import { CheckInStart } from './components/Check-in/Check-in-start';
import { CheckInReview } from './components/Check-in/Check-in-review';
import { TEAMS } from './constants/TEAMS';
import { CheckInFinished } from './components/Check-in/Check-in-finished';
import { Reservations } from './pages/reservations';

import { PrivateRoute } from 'components/core/PrivateRoute/PrivateRoute';
import { useGAPI } from 'hooks/useGAPI';
import { Layout } from 'components/core/Layout/Layout';
import { routes } from 'routes';

function App() {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const gapi = useGAPI();

  useEffect(() => {
    if (!gapi) return;

    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          hosted_domain: process.env.REACT_APP_GOOGLE_HOSTED_DOMAIN
        })
        .then(authInstance => {
          setIsLoggedIn(authInstance && authInstance.isSignedIn.get());
          authInstance.isSignedIn.listen(val => {
            setIsLoggedIn(val);
            setIdToken(
              authInstance.currentUser.get().getAuthResponse().id_token
            );
          });
          setIdToken(authInstance.currentUser.get().getAuthResponse().id_token);
          setLoading(false);
        });
    });
  }, [gapi]);

  const signIn = async () => {
    if (!gapi) return;

    const authInstance = gapi.auth2.getAuthInstance();
    authInstance
      .signIn()
      .then()
      .catch(err => {
        if (
          err.reason ===
          'Account domain does not match hosted_domain specified by gapi.auth2.init.'
        ) {
          alert(
            'This Google account does not have access to this website. Please try a different account'
          );
        } else if (err.error !== 'popup_closed_by_user') {
          alert('Ops... Something went wrong. Please try again.');
        }
      });
  };

  const signOut = () => {
    if (!gapi) return;

    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut();
    auth2.disconnect();
  };

  if (loading) {
    return (
      <div className="loader">
        <ClipLoader size={100} color={'#29ace3'} />
      </div>
    );
  }

  return (
    <Layout>
      <Router>
        <AuthInterceptor idToken={idToken} />
        <Header isLoggedIn={isLoggedIn} signOut={signOut} signIn={signIn} />
        <Switch>
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={routes.home}
            exact
            component={Home}
          />
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={routes.checkIn}
            exact
            component={CheckInStart}
            teams={TEAMS}
          />
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={routes.checkInReview}
            exact
            component={CheckInReview}
          />
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={routes.checkinFinished}
            exact
            component={CheckInFinished}
          />
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={routes.reservations}
            exact
            component={Reservations}
          />
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={routes.moodOverTime}
            exact
            component={MoodOverTime}
          />
        </Switch>

        <Route path={routes.signIn}>
          <SignIn isLoggedIn={isLoggedIn} />
        </Route>
      </Router>
    </Layout>
  );
}

export { App };
