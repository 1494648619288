const apiUrl = process.env.REACT_APP_API_BASE_URL;
export const API_ENDPOINTS = {
  BASE: apiUrl,
  FETCH_CHECKIN: `${apiUrl}/fetchCheckin`,
  MOOD_SUMMARY: `${apiUrl}/moodSummary`,
  MOOD_OVER_TIME_SUMMARY: `${apiUrl}/fetchMoodOvertime`,
  DOWNLOAD_RESERVATIONS_PER_DAY: `${apiUrl}/reservations/download`,
  RESERVATIONS: `${apiUrl}/reservations`,
  RESERVATIONS_SETTINGS: `${apiUrl}/reservations/allSettings`,
  RESERVATIONS_UPDATE_SETTINGS: `${apiUrl}/reservations/updateSettings`,
  DOWNLOAD_DIRECT_CONTACT: `${apiUrl}/reservations/downloadDirectContact`,
  FETCH_TEAMS: `${apiUrl}/teams`
};
