const MOODS = [
  { id: 1, description: 'worst' },
  { id: 2, description: 'very bad' },
  { id: 3, description: 'bad' },
  { id: 4, description: 'meh' },
  { id: 5, description: 'so-so' },
  { id: 6, description: 'okey' },
  { id: 7, description: 'good' },
  { id: 8, description: 'very good' },
  { id: 9, description: 'great' },
  { id: 10, description: 'really great' }
];

export { MOODS };
