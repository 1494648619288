import { Route, Redirect } from 'react-router-dom';

import { routes } from 'routes';

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) =>
  !isLoggedIn ? (
    <Redirect to={routes.signIn} />
  ) : (
    <Route {...rest} render={props => <Component {...props} {...rest} />} />
  );

export { PrivateRoute };
