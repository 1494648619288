import { Link } from 'react-router-dom';

import styles from './GoBackLink.module.css';
import BackArrow from './assets/go-back.png';

import { routes } from 'routes';

const GoBackLink = () => (
  <Link to={routes.home} className={styles.root}>
    <img
      src={BackArrow}
      alt="Go back link"
      className={styles.img}
      height="75"
    ></img>
  </Link>
);

export { GoBackLink };
