import PropTypes from 'prop-types';

const propTypes = {
  team: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  onTeamSelect: PropTypes.func.isRequired
};

const Team = ({ team, onTeamSelect }) => (
  <div className="p-2">
    <input
      className="team__input-radio hidden"
      type="radio"
      name="team"
      id={team.id}
      value={team.title}
      onClick={e => {
        onTeamSelect(e.target.value);
      }}
    />
    <label htmlFor={team.id} className="team__input-label p-2">
      {team.title}
    </label>
  </div>
);

Team.propTypes = propTypes;

export { Team };
