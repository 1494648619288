const TEAMS = [
  {
    id: 'clientDelivery',
    title: 'Client delivery'
  },
  {
    id: 'creative',
    title: 'Creative'
  },
  {
    id: 'analytics',
    title: 'Analytics'
  },
  {
    id: 'dpm',
    title: 'Digital Product Management'
  },
  {
    id: 'strategy',
    title: 'Strategy'
  },
  {
    id: 'backEnd',
    title: 'Back-end'
  },
  {
    id: 'devOps',
    title: 'DevOps'
  },
  {
    id: 'frontEnd',
    title: 'Front-end'
  },
  {
    id: 'leadership',
    title: 'Engineering Senior Leadership'
  },
  {
    id: 'mobile',
    title: 'Mobile'
  },
  {
    id: 'qa',
    title: 'QA'
  },
  {
    id: 'solutionArchitect',
    title: 'Solution architect'
  },
  {
    id: 'technicalArchitect',
    title: 'Technical Architect'
  },
  {
    id: 'security',
    title: 'Security'
  },
  {
    id: 'finance',
    title: 'Finance'
  },
  {
    id: 'hr',
    title: 'HR'
  },
  {
    id: 'recruitment',
    title: 'Recruitment'
  },
  {
    id: 'marketing',
    title: 'Marketing'
  },
  {
    id: 'operations',
    title: 'Operations'
  },
  {
    id: 'itsupport',
    title: 'IT-support'
  },
  {
    id: 'sales',
    title: 'Sales'
  }
];

export { TEAMS };
