import { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_regression from '@amcharts/amcharts4/plugins/regression';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import styles from './MoodChart.module.css';

const MoodChart = ({ data }) => {
  am4core.useTheme(am4themes_animated);
  let chart = useRef(null);

  useEffect(() => {
    // Create chart instance
    chart.current = am4core.create('chartContainer', am4charts.XYChart);

    // Enable chart cursor
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.lineX.disabled = true;
    chart.current.cursor.lineY.disabled = true;

    // Create the axes
    const dateAxisX = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxisX.dataFields.category = 'Date';
    dateAxisX.renderer.grid.template.location = 0.5;
    dateAxisX.renderer.minGridDistance = 40;
    dateAxisX.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
    dateAxisX.tooltipDateFormat = 'MMM dd, yyyy';
    dateAxisX.dateFormats.setKey('day', 'dd');

    // Create value axis
    const valueAxisY = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.title.text = 'Avg. Mood';

    // Enable scroll on the X axis
    chart.current.scrollbarX = new am4core.Scrollbar();

    // Create series
    const lineSeries = chart.current.series.push(new am4charts.LineSeries());
    lineSeries.tooltipText = '{date}\n[bold font-size: 17px]value: {valueY}[/]';
    lineSeries.dataFields.valueY = 'avg';
    lineSeries.dataFields.dateX = 'date';
    lineSeries.strokeOpacity = 0.7;
    lineSeries.strokeWidth = 2;
    lineSeries.strokeDasharray = '3,3';

    // Add a bullet
    const bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.strokeWidth = 2;
    bullet.stroke = am4core.color('#fff');
    bullet.setStateOnChildren = true;
    bullet.propertyFields.fillOpacity = 'opacity';
    bullet.propertyFields.strokeOpacity = 'opacity';

    const hoverState = bullet.states.create('holver');
    hoverState.properties.scale = 1.7;

    // Drew the Trend line
    const regseries = chart.current.series.push(new am4charts.LineSeries());
    regseries.dataFields.valueY = 'avg';
    regseries.dataFields.dateX = 'date';
    regseries.strokeWidth = 2;
    regseries.name = 'Linear Regression';

    regseries.plugins.push(new am4plugins_regression.Regression());

    return () => {
      chart.current.dispose();
    };
  }, []);

  useEffect(() => {
    chart.current.data = data;
  }, [data]);

  return <div id="chartContainer" className={styles.root} />;
};

export { MoodChart };
