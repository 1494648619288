import { useCallback, useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import isWeekend from 'date-fns/isWeekend';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

import { API_ENDPOINTS } from 'constants/API-ENDPOINTS';
import { ReservationEventModal } from 'components/ReservationEventModal';

const locales = {
  'en-US': require('date-fns/locale/en-US')
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

class CalendarEvent {
  title;
  start;
  end;
  allDay = true;
  description = '';
  numberOfSeats = 60;
}

const ReservationSettings = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const { addToast } = useToasts();

  const fetchSettings = useCallback(async () => {
    const result = await axios.get(API_ENDPOINTS.RESERVATIONS_SETTINGS);

    setEvents(parseData(result.data));
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const parseData = settings => {
    const parsedEvents = [];

    settings.forEach(setting => {
      const date = format(setting.timestamp, 'yyyy-MM-dd');

      parsedEvents.push({
        title: `Available: ${setting.numberOfSeats}`,
        start: date,
        end: date,
        allDay: true,
        ...setting
      });
    });

    return parsedEvents;
  };

  const handleSelect = ({ start }) => {
    const date = format(start, 'yyyy-MM-dd');
    if (isWeekend(start)) {
      addToast(`The selected date "${date}" is a weekend`, {
        appearance: 'info'
      });
      return false;
    }

    const existingEvent = events.find(event => event.start === date);

    if (existingEvent) {
      addToast(`An event already exists for the date "${date}"`, {
        appearance: 'info'
      });
      return false;
    }

    const ev = new CalendarEvent();
    ev.start = date;
    ev.end = date;

    setSelectedEvent(ev);
    setShowModal(true);
  };

  const handleSelectEvent = event => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleSubmit = async data => {
    try {
      await axios.post(API_ENDPOINTS.RESERVATIONS_UPDATE_SETTINGS, {
        description: data.description,
        numberOfSeats: data.numberOfSeats,
        timestamp: new Date(data.start).getTime()
      });

      fetchSettings();
    } catch (e) {
      addToast(e.response.data.messages[0], { appearance: 'error' });
    }

    setShowModal(false);
  };

  return (
    <div>
      <Calendar
        selectable
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectSlot={handleSelect}
        onSelectEvent={handleSelectEvent}
      />
      <ReservationEventModal
        show={showModal}
        event={selectedEvent}
        onHide={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export { ReservationSettings };
