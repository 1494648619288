import { Navigation } from 'components/Navigation/Navigation';
import { Title } from 'components/Title/Title';

const Home = () => {
  return (
    <div>
      <Title>Welcome back</Title>
      <Navigation />
    </div>
  );
};

export { Home };
