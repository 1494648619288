import PropTypes from 'prop-types';
import cx from 'classnames';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { VisuallyHidden } from '@reach/visually-hidden';
import '@reach/dialog/styles.css';

import styles from './Modal.module.css';

const defaultProps = {
  closeLabel: 'Close',
  actionLabel: 'Save changes'
};

const propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  closeLabel: PropTypes.string,
  actionLabel: PropTypes.string.isRequired
};

const Modal = ({
  open,
  title,
  children,
  handleClose,
  handleAction,
  closeLabel,
  actionLabel
}) => (
  <DialogOverlay
    isOpen={open}
    onDismiss={handleClose}
    style={{
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 99
    }}
  >
    <DialogContent className={styles.content} aria-label={title}>
      <h2 className={cx(styles.title, styles.separator)}>{title}</h2>
      <button className={styles.closeIcon} onClick={handleClose}>
        <VisuallyHidden>Close</VisuallyHidden>
        <span aria-hidden>×</span>
      </button>
      <div className={styles.separator}>{children}</div>
      <div className={styles.wrapper}>
        <button
          className={cx(styles.button, styles.closeButton)}
          onClick={handleClose}
        >
          {closeLabel}
        </button>
        <button
          className={cx(styles.button, styles.actionButton)}
          onClick={handleAction}
        >
          {actionLabel}
        </button>
      </div>
    </DialogContent>
  </DialogOverlay>
);

Modal.defaultProps = defaultProps;
Modal.propTypes = propTypes;

export { Modal };
