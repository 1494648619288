import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

import { Firebase } from 'components/Firebase';
import { FirebaseContext } from 'components/Firebase/context';

import './index.scss';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ToastProvider placement="top-center" autoDismiss={true}>
      <App />
    </ToastProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
