import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from './assets/Logo.svg';
import styles from './Header.module.css';

import { routes } from 'routes';

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired
};

const Header = ({ isLoggedIn, signOut, signIn }) => (
  <header className={styles.root}>
    <Link to={routes.home} className={styles.wrapper}>
      <Logo aria-label="Mobiquity logo" />
    </Link>
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={isLoggedIn ? signOut : signIn}>
        {isLoggedIn ? 'Sign out' : 'Login'}
      </button>
    </div>
  </header>
);

Header.propTypes = propTypes;

export { Header };
