import { Link } from 'react-router-dom';

import styles from './Navigation.module.css';
import { ReactComponent as SmileIcon } from './assets/SmileIcon.svg';
import { ReactComponent as CalendarIcon } from './assets/CalendarIcon.svg';
import { ReactComponent as ReportsIcon } from './assets/ReportsIcon.svg';

import { routes } from 'routes';

const navigation = [
  {
    title: 'Reservations',
    icon: CalendarIcon,
    route: routes.reservations
  },
  {
    title: 'Check In',
    icon: SmileIcon,
    route: routes.checkIn
  },
  {
    title: 'Mood Over Time',
    icon: ReportsIcon,
    route: routes.moodOverTime
  }
];

const Navigation = () => (
  <nav className={styles.root}>
    <ul className={styles.row}>
      {navigation.map(item => {
        const { title, icon: Icon, route } = item;

        return (
          <li className={styles.col} key={title}>
            <Link className={styles.title} to={route}>
              <Icon className={styles.icon} />
              {title}
            </Link>
          </li>
        );
      })}
    </ul>
  </nav>
);

export { Navigation };
