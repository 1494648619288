import { Link } from 'react-router-dom';

import { routes } from 'routes';

const NoSubmissions = () => (
  <div className="submissions">
    <p>No submissions found, please try again.</p>
    <Link to={routes.home}>
      <button type="button" className="check-in-button">
        Back
      </button>
    </Link>
  </div>
);
export { NoSubmissions };
