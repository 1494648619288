import { MOODS } from 'constants/MOODS';

const TeamMemberMood = props => {
  const { mobster } = props;

  if (!mobster) {
    return null;
  }
  const mood = MOODS.find(m => m.id === +mobster.score.score);
  return (
    <div className="d-flex flex-column align-items-center">
      <img
        src={`/images/moods/${mood.id}.svg`}
        className="teamMemberMood__img"
        alt=""
      ></img>
      <p className="teamMemberMood__paragraph">
        {mobster.mobsterName}
        <span className="teamMemberMood__paragraph--black"> is feeling </span>
        <span>{mood.description}</span>
        <span className="teamMemberMood__paragraph--black"> today!</span>
      </p>
      <div>
        <button
          type="button"
          className="check-in-button"
          onClick={() => props.fetchMobster()}
        >
          Next mobster
        </button>
      </div>
    </div>
  );
};

export { TeamMemberMood };
